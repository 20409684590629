import SectionWrapper from "../../../components/SectionWrapper";
import {
  Alarm,
  AnomalyGraphDataMap,
  Asset,
  Building,
  Device,
  DeviceDeployment, ModelTemplate,
  Room
} from "../../../types/dataTypes";
import Titlebar from "../../../components/Titlebar";
import Button from "../../../components/Button";
import {TempState} from "../../../types/componentTypes";
import React, {useEffect, useState} from "react";
import {
  getAnomalyDetectionResults,
  postAlarmFeedback,
  putAlarm
} from "../../../api/api";
import EmojiRating from "./EmojiRating";
import TrafficLight from "../../../components/TrafficLight";
import AnomalyGraph from "../../../components/AnomalyGraph";
import { getAnomalyDetectionResultParallel, getModelTemplates } from "../../../api/api-ts"
import {SimpleGraphDates, simpleGraphTimeConverter} from "../../../components/AnomalyGraph/Components/SimpleDateSelect";
import {Link} from "react-router-dom";
import {useNavigate} from "react-router";
import { chunk } from "../../../functions/chunk"


type AlarmDialogProps = {
  alarm: Alarm,
  tempState: TempState
}

export default function AlarmDialog({alarm, tempState}: AlarmDialogProps) {

  const [loadedAlarm, setLoadedAlarm] = useState<Alarm>(alarm)
  const [modelTemplates, setModelTemplates] = useState<ModelTemplate[]>([])
  const [anomalyGraphData, setAnomalyGraphData] = useState<AnomalyGraphDataMap>({})
  const [hover, setHover] = useState(false)
  const [showResolve, setShowResolve] = useState(false)
  const [resolveInput, setResolveInput] = useState<string>(loadedAlarm.resolution_description ?? "")
  const [resolveCheck, setResolveCheck] = useState<boolean>(loadedAlarm.resolved)
  const [alarmRating, setAlarmRating] = useState<number>()
  const [alarmComment, setAlarmComment] = useState<string>()
  const [graphDateTime, setGraphDateTime] = useState<SimpleGraphDates>("Last 7 days")
  const [loading, setLoading] = useState<boolean>(false)

  const device: Device | undefined = tempState.devices.find(d => d.id === loadedAlarm.device_id)
  const deviceDeployment: DeviceDeployment | undefined = tempState.deviceDeployments?.find(dd => dd.device_id === loadedAlarm.device_id)
  const room: Room | undefined = tempState.rooms?.find(r => r.id === deviceDeployment?.room_id)
  const building: Building | undefined = tempState.buildings?.find(b => b.id === room?.building_id)
  const assets: Asset[] | undefined = deviceDeployment?.assets

  const endTime = new Date(loadedAlarm.timestamp * 1000 + 1 * 24 * 60 * 60 * 1000);
  const startTime = new Date(endTime.getTime() - simpleGraphTimeConverter(graphDateTime) * 24 * 60 * 60 * 1000)

  const navigate = useNavigate()

  useEffect(() => {
    setLoading(true)
    const fetchAndSetAnomalyDetectionResults = async () => {
      let anomaly_graph_start_time = startTime
      let modelTemplates: ModelTemplate[] = await getModelTemplates([loadedAlarm.device_id], [],
        [], undefined, undefined, undefined,
        undefined, false)
      let filteredModelTemplates = modelTemplates.filter(mt => mt.enabled && ((mt.end_time === undefined || mt.end_time === null)
        || mt.end_time > anomaly_graph_start_time.getTime() / 1000) && mt.start_time < endTime.getTime()/1000)
     let anomalyGraphDataResult: AnomalyGraphDataMap = await getAnomalyDetectionResultParallel(4, filteredModelTemplates, alarm.device_id, anomaly_graph_start_time, endTime)
      setModelTemplates(filteredModelTemplates)
      setAnomalyGraphData(anomalyGraphDataResult)
      setLoading(false)
    }
    fetchAndSetAnomalyDetectionResults()
  }, [graphDateTime])


  function handleChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    setResolveInput(event.target.value)
  }

  function handleCommentChange(event: React.ChangeEvent<HTMLInputElement>) {
    setAlarmComment(event.target.value)
  }

  function handleResolvedChange(event: React.ChangeEvent<HTMLInputElement>) {
    setResolveCheck(event.target.checked)
  }

  async function handleSubmit(event: React.SyntheticEvent) {
    event.preventDefault()
    let updatedAlarm: Alarm = await putAlarm(loadedAlarm.id, loadedAlarm.resolved ? {"resolution_description": resolveInput} : {
      "resolution_description": resolveInput,
      "resolved": !loadedAlarm.resolved
    })

    if (alarmRating != undefined) {
      postAlarmFeedback(loadedAlarm.id, alarmRating, alarmComment)
    }
    setLoadedAlarm(updatedAlarm)
    setShowResolve(false)

  }

  function cancelResolve() {
    setShowResolve(false)
    setResolveInput(loadedAlarm.resolution_description ?? "")
    setAlarmRating(undefined)
    setAlarmComment(undefined)
  }

  function constructAlarmLink(building?: Building, room?: Room, assets?: Asset[]) {
    let base = "/?"
    base += `${building !== undefined ? "orgId=" + building.organization_id + "&buildingId=" + building.id : ""}`
    base += `&${room !== undefined ? "roomId=" + room.id : ""}`
    base += `&${assets !== undefined && assets.length === 1 ? "assetId=" + assets[0].id : ""}`
    return base
  }

  return (
    <SectionWrapper styles="tw-border tw-ml-10 tw-p-5 tw-my-5 tw-mr-10">
      <div onClick={() => navigate(-1)} className="tw-basis-10 tw-absolute tw-right-20 tw-top-5 lg:tw-top-10 tw-pl-10 tw-py-6">
          <img
            src="icon-arrow-circled.svg"
            alt="exit icon"
            className="tw-cursor-pointer"
          />
      </div>
      <Titlebar headline="Alarm"/>
      {device &&
      (<AnomalyGraph alarms={[alarm]} modelTemplates={modelTemplates} anomalyGraphDataMap={anomalyGraphData}
                     device={device} hovermode="closest"
                     navigateToAlarmOnClick={false} selectedGraphTime={graphDateTime}
                     onUpdateGraphTime={graphTime => setGraphDateTime(graphTime)}
                     graphEndTime={new Date(loadedAlarm.timestamp * 1000 + 24 * 60 * 60 * 1000)} loading={loading}/>)}

      <div className="tw-ml-10 tw-mt-10">
        <div className="tw-grid tw-grid-cols-3 tw-gap-4">
          <div className="tw-text-gray-500 tw-font-bold">
            Description
          </div>
          <div className="tw-col-start-2 tw-col-end-4 lg:tw-col-end-3 tw-text-lg">
            {loadedAlarm.description}
          </div>
          <div className="tw-col-start-1 tw-col-end-4 lg:tw-col-end-3 tw-border-b tw-border-gray-300"/>
          <div className="tw-col-start-1 tw-text-gray-400">Location</div>
          <div className="tw-col-start-2 tw-col-end-4 lg:tw-col-end-3">
            <table className="tw-table-fixed tw-w-full tw-text-sm tw-text-left tw-text-primary-main-black">
              <tbody>
              <tr>
                <th>Building</th>
                <td><Link
                  to={constructAlarmLink(building)}>{building ? (building.name ?? building.address) : "Loading"} </Link>
                </td>
              </tr>
              <tr>
                <th>Room</th>
                <td><Link
                  to={constructAlarmLink(building, room)}>{room ? (room.nice_name ?? room.name) : "Loading"} </Link>
                </td>
              </tr>
              <tr>
                <th>Assets</th>
                <td><Link to={constructAlarmLink(building, room, assets)}>{assets ? (assets.map((a, index) => {
                    return ((index != 0 ? ", " : "") + (a.nice_name ?? a.name))
                  }
                )) : "Loading"}</Link></td>
              </tr>
              <tr>
                <th>Device</th>
                <td>{device ? (device.nickname ?? device.serial) : loadedAlarm.device_id}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div className="tw-col-start-1 tw-col-end-4 lg:tw-col-end-3 tw-border-b tw-border-gray-300"/>
          <div className="tw-col-start-1 tw-text-gray-400">Information</div>
          <div className="tw-col-start-2 tw-col-end-4 lg:tw-col-end-3">
            <table className="tw-table-fixed tw-w-full tw-text-sm tw-text-left tw-text-primary-main-black">
              <tbody>
              <tr>
                <th>Alarm Id</th>
                <td>{loadedAlarm.id}</td>
              </tr>
              <tr>
                <th>Alarm Type</th>
                <td className="tw-flex">
                  <div className="tw-inline-flex">{loadedAlarm.alarm_type + "-Alarm"}</div>
                  <TrafficLight status={loadedAlarm.alarm_type === "A" ? "CRITICAL" : "WARNING"}/></td>
              </tr>
              <tr>
                <th>Time</th>
                <td>{new Date(loadedAlarm.timestamp * 1000).toLocaleString()} </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div className="tw-col-start-1 tw-col-end-4 lg:tw-col-end-3 tw-border-b tw-border-gray-300"/>
          <div className="tw-col-start-1 tw-text-gray-400">Status</div>
          <div className="tw-col-start-2 tw-col-end-4 lg:tw-col-end-3">
            <table className="tw-table-fixed tw-w-full tw-text-sm tw-text-left tw-text-primary-main-black">
              <tbody>
              <tr>
                <th>Resolved</th>
                <td>{loadedAlarm.resolved ? (<div className={"tw-text-green-700 tw-inline"}>Yes</div>) :
                  (<div className={"tw-text-red-700 tw-inline"}>No</div>)}</td>
              </tr>
              <tr>
                <th>Resolved at</th>
                <td>{(loadedAlarm.resolved && loadedAlarm.resolution_time) ? (<> {new Date(loadedAlarm.resolution_time * 1000).toLocaleString()} </>) : "__.__.____, __:__"}</td>
              </tr>
              <tr>
                <th>Note</th>
                <td>{loadedAlarm.resolution_description}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {!showResolve && (
        <Button styles="tw-ml-10 tw-mt-5" onClick={() => setShowResolve(true)} type="button"
                size="medium">{loadedAlarm.resolved ? "Unresolve" : "Resolve"}</Button>
      )}
      {showResolve && (
        <form className="tw-flex-col tw-ml-10 tw-mr-10 tw-mt-5 tw-rounded-normal tw-border-primary-main-black tw-border tw-p-2"
              onSubmit={handleSubmit}>
          {loadedAlarm.resolved ? "" : <div id="alarm-feedback">
            {/*<div className="tw-flex tw-flex-row tw-p-2">
              <p className="tw-mr-1">How happy were you with this alarm?</p>
            </div>
            <div className="tw-flex tw-flex-row tw-p-2">
              <EmojiRating setRating={setAlarmRating} currentRating={alarmRating} className={""}/>
            </div>*/}
          </div>}
          <div className="tw-flex tw-flex-row tw-p-2">
              <p className="tw-mr-1">Describe what triggered the alarm, and what was done to resolve the issue. </p>
            </div>
          <div className="lg:tw-flex tw-items-center tw-py-2 tw-ml-1">
            <textarea
              className="tw-block tw-rounded tw-appearance-none tw-bg-transparent tw-border tw-w-full tw-text-gray-700 tw-mr-3 tw-p-4 tw-px-2 tw-leading-tight tw-focus:tw-outline-none"
              placeholder="The problem was that..." defaultValue={loadedAlarm.resolution_description}
              aria-label="Description" name="description"
              onChange={handleChange}/>
          </div>
          <div className="tw-flex tw-flex-row-reverse">
              <button
                className="tw-flex-shrink-0 tw-bg-primary-main-black hover:tw-bg-teal-700 tw-border-2  tw-text-white tw-text-sm  tw-py-1 tw-px-2 tw-rounded-full"
                type="submit">
                Submit
              </button>
              <button
                className="tw-flex-shrink-0 tw-bg-white hover:tw-bg-red-200 tw-border-2 tw-text-primary-main-black tw-text-sm tw-py-1 tw-px-2 tw-rounded-full"
                type="button" onClick={() => cancelResolve()}>
                Cancel
              </button>
            </div>
        </form>
      )}
    </SectionWrapper>
  )
}